import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { Business } from 'models/Business';
import { Division } from 'models/Division';
import { useMemo } from 'react';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useAppSelector } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const { selectedCompanyDataAreaId } = useAppSelector((state) => state.app);
  const { data: businesses, isLoading, isFetching } = useGetBusinessesQuery({ includeDivisions: true, businessDataAreaId: selectedCompanyDataAreaId });

  /* ******************** Functions / Variables ******************** */
  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (businesses) {
      for (const business of businesses.data) {
        // Map the divisions
        let fullDivisions: Division[] = [];

        for (const division of business.divisions) {
          fullDivisions = [...fullDivisions, ...[division]];
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [businesses, customerSearch]);

  /* ******************** Render ******************** */
  return (
    <List
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching,
        wrapperClassName: isLoading || isFetching ? 'list-loading' : ''
      }}
      dataSource={filteredData}
      renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
      rowKey={(item): string => item.id}
    />
  );
};
