import { Button, message, Row } from 'antd';
import { ProjectType } from 'models/Project';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoverProjectMutation } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { setIsProjectRcovering, setSelectedProjects } from 'redux/slices/selectedProjectsSlice';
import { ReduxState } from 'redux/store';
interface Props {
  projectsData: ProjectType[] | undefined;
}

const RecoverActions = ({ projectsData }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [purgeCount, _setPurgeCount] = useState(1);
  const { selectedProjects } = useSelector((state: ReduxState) => state.selectedProjects);

  const [recoverProject, { isLoading: recoveringProject }] = useRecoverProjectMutation({ fixedCacheKey: 'recover-quote' });
  // const [purgeSalesOrder, { isLoading: isPurgingSalesOrder }] = usePurgeSalesOrderMutation();

  const totalSelectedSalesOrders = selectedProjects.length;

  useEffect(() => {
    if (recoveringProject) dispatch(setIsProjectRcovering(true));
    else dispatch(setIsProjectRcovering(false));
  }, [recoveringProject]);

  const handleRestoreSelectedRoles = async (): Promise<void> => {
    if (selectedProjects.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const project of selectedProjects) {
      try {
        await recoverProject({ divisionId: project.division.id, projectId: project.id }).unwrap();
      } catch {
        message.error(`Project ${project.customerProjectId} failed to be recovered!`);
      }
    }

    dispatch(setSelectedProjects([]));
    message.success(`Project(s) were successfully recovered!`);
  };

  const handleSelectAll = (): void => {
    if (projectsData !== undefined) {
      dispatch(setSelectedProjects(projectsData));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedProjects([]));
  };

  return (
    <Row style={{ paddingTop: 10 }} justify="space-between">
      <Row justify="space-between">
        <Button onClick={handleSelectAll} style={{ marginRight: 5 }} type="primary" disabled={!projectsData?.length}>
          Select All
        </Button>
        <Button onClick={handleClearAll} ghost danger disabled={!totalSelectedSalesOrders}>
          Clear All
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreSelectedRoles}
          style={totalSelectedSalesOrders ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={recoveringProject}
          disabled={!totalSelectedSalesOrders}>
          Restore Selected ({totalSelectedSalesOrders})
        </Button>
      </Row>
    </Row>
  );
};

export default RecoverActions;
