import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import { colors, toRgb } from 'common/styles/colors';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { ProjectType } from 'models/Project';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedProject, removeSelectedProjcet } from 'redux/slices/selectedProjectsSlice';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  card: {
    borderRadius: 5
  },
  cardBody: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15
  },
  largeText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000'
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#636770'
  },
  subTitle: {
    fontSize: '15px',
    color: '#A0A3AE',
    minHeight: '27px'
  },
  text: {
    fontSize: '13px',
    color: '#A0A3AE'
  },
  smallText: {
    fontSize: '11px',
    color: 'lightGrey'
  },
  status: {
    fontSize: '16px',
    fontWeight: 700
  },
  draft: {
    color: toRgb(colors.orangeWeb)
  },
  sent: {
    color: toRgb(colors.robinEggBlue)
  },
  awarded: {
    color: toRgb(colors.illuminatingEmerald)
  },
  lost: {
    color: toRgb(colors.redSalsa)
  },
  soNumber: {
    fontSize: '14px',
    color: '#5868DD'
  },
  poNumber: {
    fontSize: '14px',
    color: 'gray'
  },
  customerId: {
    fontSize: '16px',
    color: '#636770'
  },
  iconMenuButton: {
    width: '100%'
  },
  iconMenuButtonDuplicate: {
    width: '100%',
    color: toRgb(colors.illuminatingEmerald)
  }
};

interface Props {
  project: ProjectType;
}

const RecoverSalesOrderCard = ({ project }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedProjects } = useSelector((state: ReduxState) => state.selectedProjects);

  const isSelected = selectedProjects.some((item) => item.id === project.id);

  const handleToggleSelectProduct = (): void => {
    if (isSelected) {
      dispatch(removeSelectedProjcet(project));
    } else {
      dispatch(addSelectedProject(project));
    }
  };

  const cardJSX = (
    <Card style={styles.card} bodyStyle={styles.cardBody}>
      <Row gutter={8} justify="space-between">
        <Col span={20} style={{ marginBottom: 15 }}>
          <Typography.Text ellipsis={{ tooltip: `${project.customerProjectId}` }} style={styles.largeText}>
            <Tooltip title="Quote ID" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {project.customerProjectId}
            </Tooltip>
          </Typography.Text>
        </Col>
        <Button style={{ borderRadius: '50%' }} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
      </Row>
      <Row gutter={8} justify="space-between">
        <Col>
          <Typography style={styles.subTitle}>
            <Tooltip title="Customer Name" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {project.description}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Row gutter={8} justify="space-between">
        <Col style={{ textAlign: 'right' }}>
          <Typography style={styles.text}>
            <Tooltip title="Created By" placement="left" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {project.status}
            </Tooltip>
          </Typography>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between">
        <Row>
          <Col span={2}>
            <Divider type="vertical" style={{ fontSize: 40 }} />
          </Col>
        </Row>
        <Col style={{ textAlign: 'end', marginRight: 10 }} span={10}>
          <Typography.Paragraph style={{ fontSize: '14px', color: 'gray' }}>
            <Tooltip title="Creation Date" placement="top" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
              {project?.projectType}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      {cardJSX}
    </List.Item>
  );
};

export default RecoverSalesOrderCard;
