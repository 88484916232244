import { Col, message, Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { ActionControls } from 'components/molecules/ActionControls';
import { ProjectForm } from 'components/molecules/ProjectForm';
import { TasksList } from 'components/molecules/TaskList';
import { HeaderCard } from 'components/organisms/HeaderCard';
import { FormikProvider, useFormik } from 'formik';
import { projectPayload, ProjectTypePayload } from 'models/Project';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateProjectMutation } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { useAppSelector } from 'redux/store';

export const CreateProjectPage = (): JSX.Element => {
  const [createProject] = useCreateProjectMutation();
  const { divisionId } = useParams();
  const nav = useNavigate();
  const { exit, user } = useAppSelector((state) => state.app);

  const formik = useFormik<ProjectTypePayload>({
    enableReinitialize: true,
    validationSchema: projectPayload,
    initialValues: {
      isActive: true,
      customerProjectId: '',
      description: '',
      projectType: '',
      isBudgetOnHold: false,
      owner: {
        fullName: user?.name ?? '',
        userId: user?.sub ?? ''
      },
      status: '',
      tasks: [],
      users: [],
      userDefinedFields: []
    },
    onSubmit: async (vals) => {
      try {
        if (!divisionId) return message.error('An error occured, the team has been notified');
        const response = await createProject({ divisionId, payload: vals }).unwrap();

        message.success(`${response.customerProjectId} has been successfully created`);
        if (exit) nav(`/${divisionId}/projects`);
        else nav(`/${divisionId}/projects/${response.id}`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  return (
    <Spin spinning={formik.isSubmitting} indicator={<Loader message="Creating Project" />}>
      <FormikProvider value={formik}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Row justify="space-between">
            <Col>
              <Typography.Title style={{ padding: 0, margin: 0, fontWeight: 300 }} level={4}>
                Create Project
              </Typography.Title>
            </Col>
            <ActionControls />
          </Row>
          <HeaderCard title="General" headerColor="rgb(206, 213, 242)">
            <ProjectForm />
          </HeaderCard>
          <TasksList />
        </Space>
      </FormikProvider>
    </Spin>
  );
};
