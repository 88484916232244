import { Card, Col, List, Row, Typography } from 'antd';
// import { useFormikContext } from 'formik';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCompany } from 'redux/slices/appSlice';
// import { addSelectedDivisions, removeSelectedDivisions } from 'redux/slices/selectedDivisionSlice';

const { Paragraph } = Typography;

interface Props {
  division: Business['divisions'][number];
}
const styles: InlineStylesModel = {
  wrapper: {
    height: '100%'
  },
  container: {
    padding: 15
  },
  title: {
    fontSize: 14,
    color: 'rgb(39, 134, 250)',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: 14,
    marginBottom: 0
  }
};

export const DivisionListItem = ({ division }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (): void => {
    dispatch(setCompany(`${division.name} - ${division.erpId}`));
    navigate(`/${division.id}/projects`);
  };

  /* ******************** Renderer ******************** */
  return (
    <List.Item style={{ marginBottom: 4, height: '100%' }}>
      <Card onClick={handleNavigate} hoverable style={styles.wrapper} bodyStyle={styles.container}>
        <Row justify="space-between" align="middle">
          <Col span={18}>
            <Paragraph style={styles.title} ellipsis>
              {division.name}
            </Paragraph>
            <Paragraph style={styles.subTitle} ellipsis={{ rows: 1, tooltip: division.erpId }}>
              {division.erpId}
            </Paragraph>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
