import { Button, Col, List, Row, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { TaskCard } from 'components/atoms/TaskCard';
import { useFormikContext } from 'formik';
import { ProjectTypePayload } from 'models/Project';
import { Fragment, LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { AddTaskModal } from './AddTaskModal';

export const TasksList: React.FC = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { values } = useFormikContext<ProjectTypePayload>();
  const [showRequestTypes, setShowRequestTypes] = useState(false);

  useEffect(() => {
    if (values?.tasks?.length) setShowRequestTypes(true);
  }, [values?.tasks?.length]);

  const handleToggleShowRequestTypes = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowRequestTypes((prevState) => !prevState);
  };

  const handleClick = (ev: MouseEvent<HTMLElement, globalThis.MouseEvent>): void => {
    ev.stopPropagation();
    setIsOpen(true);
  };

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showRequestTypes ? height : 0
  });

  const businessListJSX: JSX.Element = (
    <Row>
      <Col span={24}>
        <List
          dataSource={values?.tasks}
          loading={{ indicator: <BlockLoader direction="loader loader--slideUp" />, spinning: false }}
          grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
          style={{ width: '100%' }}
          renderItem={(item, index: number): React.ReactNode => (
            <Col>
              <TaskCard task={item} index={index} />
            </Col>
          )}
        />
      </Col>
    </Row>
  );

  return (
    <Fragment>
      <AddTaskModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Col span={24} style={{ marginBottom: showRequestTypes ? 10 : 0 }}>
        <Row onClick={handleToggleShowRequestTypes} style={{ padding: 15, background: 'rgba(24, 167, 153, 0.518)', borderRadius: 5, cursor: 'pointer' }} justify="space-between">
          <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
            Tasks
          </Typography.Title>
          <Row>
            <Col>
              <Button onClick={handleClick}>Add Task</Button>
            </Col>
            <Col>
              <Button style={{ width: 115 }} type="text" onClick={handleToggleShowRequestTypes} key="show-divisions" icon={<CaretSpinIcon isOpen={showRequestTypes} />}>
                {showRequestTypes ? 'Collapse' : 'Expand'}
              </Button>
            </Col>
          </Row>
        </Row>
        <Row style={{ background: 'transparent', padding: '0 1.25px' }}>
          <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
            {showRequestTypes && (
              <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%' }}>
                {businessListJSX}
              </div>
            )}
          </animated.div>
        </Row>
      </Col>
    </Fragment>
  );
};
