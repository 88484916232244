import { Space, Switch } from 'antd';
import { FormLabel } from 'components/UI/FormLabel';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';

interface Props {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
}

export const FieldSwitchInput: FC<Props> = ({ fieldName, placeholder, type, label }) => {
  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <Space direction="vertical">
          <FormLabel isSwitch label={label} />
          <Switch {...field} checked={field.value} style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} onChange={(value: boolean): void => setFieldValue(fieldName, value)} />
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </Space>
      )}
    </Field>
  );
};
