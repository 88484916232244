import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, List, Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/atoms/Loader';
import NoResults from 'components/atoms/noResults/noResults';
import RecoverSalesOrderCard from 'components/atoms/RecoverSalesOrderCard';
import { ProjectsFilters } from 'components/molecules/ProjectsFilters';
import RecoverActions from 'components/molecules/RecoverActions';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectsQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  divider: {
    margin: '9px 0px'
  }
};

/* ****************** interfaces ****************** */

export const Recover = (): JSX.Element => {
  const { projectParams } = useSelector((state: ReduxState) => state);
  const { isProjectRcovering } = useSelector((state: ReduxState) => state.selectedProjects);
  const nav = useNavigate();
  const { divisionId } = useParams();

  /* ****************** API/Hooks ****************** */

  const {
    data: deletedProjectsDAta,
    isLoading,
    isFetching,
    isError,
    error
  } = useGetProjectsQuery({ divisionId: divisionId as string, params: { ...projectParams, returnDeletedDataOnly: true } }, { skip: !divisionId });

  return (
    <Spin spinning={isLoading || isFetching || isProjectRcovering} indicator={<Loader message={isProjectRcovering ? 'Recovering project(s)' : undefined} />}>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row justify="space-between">
              <Typography.Text style={{ fontSize: 17, margin: 0, padding: 0 }}>Search Projects - Recycle Bin</Typography.Text>
              <Button icon={<ArrowLeftOutlined />} onClick={(): void => nav(`/${divisionId}/projects`)}>
                Back
              </Button>
            </Row>
            <Row style={{ width: '100%', background: 'white', padding: 20 }}>
              <ProjectsFilters />
            </Row>
            <RecoverActions projectsData={deletedProjectsDAta?.data} />
          </Space>
        </Col>
        <Divider style={styles.divider} />
        {deletedProjectsDAta?.data.length !== 0 ? (
          <Col span={24}>
            <List
              grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 4 }}
              dataSource={deletedProjectsDAta?.data}
              renderItem={(item): JSX.Element => <RecoverSalesOrderCard project={item} />}
              rowKey={(item): string => item.id}
              style={{ overflow: 'hidden' }}
            />
          </Col>
        ) : (
          <NoResults errorMessage="No Results Were Found..." />
        )}
      </Row>
    </Spin>
  );
};
