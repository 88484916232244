import { Col, Modal, Space, Typography, message } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FormikProvider, useField, useFormik } from 'formik';
import { Task } from 'models/Project';
import { FC } from 'react';
import * as yup from 'yup';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
}
export const taskPayload: yup.SchemaOf<Task> = yup.object({
  description: yup.string().label('Description').required(),
  id: yup.string(),
  customerTaskId: yup.string().label('Customer Task ID').required()
});

export const EditTaskModal: FC<Props> = ({ isOpen, setIsOpen, index }) => {
  const [{ value: taskValue }, , { setValue: setTaskValue }] = useField<Task>(`tasks.${index}`);
  const [{ value: taskValues }, ,] = useField<Task[]>(`tasks`);

  const filteredVals = taskValues.filter((task) => task.customerTaskId !== taskValue.customerTaskId);

  const editFormik = useFormik<Task>({
    enableReinitialize: true,
    initialValues: taskValue,
    validationSchema: taskPayload,
    onSubmit: (values): void => {
      if (filteredVals.some((task) => task.customerTaskId === values.customerTaskId)) {
        message.error('Customer task id already exists');

        return;
      }
      setTaskValue({ ...taskValue, ...values });
      setIsOpen(false);
      editFormik.resetForm();
    }
  });

  const handleCancel = (): void => {
    setIsOpen(false);
    editFormik.resetForm();
  };

  return (
    <>
      <Modal
        destroyOnClose
        okText="Submit"
        okButtonProps={{ disabled: editFormik.initialValues === editFormik.values }}
        onOk={(): Promise<void> => editFormik.submitForm()}
        onCancel={handleCancel}
        open={isOpen}>
        <FormikProvider value={editFormik}>
          <Col span={24} style={{ background: 'rgb(255, 234, 193)', borderRadius: 10, marginBottom: 10, marginTop: 20 }}>
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
              Edit Task
            </Typography.Title>
          </Col>
          <Col style={{ margin: '20px 10px' }}>
            <Space style={{ width: '100%' }} direction="vertical" size={15}>
              <FieldInput label="Name" fieldName="customerTaskId" />
              <FieldInput label="Description" fieldName="description" />
            </Space>
          </Col>
        </FormikProvider>
      </Modal>
    </>
  );
};
