import { Space } from 'antd';
import { ProjectDescriptionFilter } from 'components/atoms/filters/ProjectDescriptionFilter';
import { ProjectNameFilter } from 'components/atoms/filters/ProjectNameFilter';

export const ProjectsFilters = (): JSX.Element => {
  return (
    <Space>
      <ProjectNameFilter />
      <ProjectDescriptionFilter />
    </Space>
  );
};
