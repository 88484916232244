import { Card, Typography } from 'antd';
import { FC } from 'react';

interface Props {
  title: string;
  headerColor: string;
  children: React.ReactNode;
}

export const HeaderCard: FC<Props> = ({ title, headerColor, children }) => {
  return (
    <Card
      style={{ marginBottom: 5, height: '100%' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          {title}
        </Typography.Title>
      }
      bodyStyle={{ padding: 20 }}
      headStyle={{ background: headerColor }}>
      {children}
    </Card>
  );
};
