import { Col, message, Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { ActionControls } from 'components/molecules/ActionControls';
import { ProjectForm } from 'components/molecules/ProjectForm';
import { TasksList } from 'components/molecules/TaskList';
import { HeaderCard } from 'components/organisms/HeaderCard';
import { FormikProvider, useFormik } from 'formik';
import { projectPayload, ProjectTypePayload } from 'models/Project';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteProjectMutation, useGetAssignedDeploymentRequestsQuery, useGetProjectQuery, useUpdateProjectMutation } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { useAppSelector } from 'redux/store';

export const EditProjectPage = (): JSX.Element => {
  const { divisionId, projectId } = useParams();
  const { exit, user } = useAppSelector((state) => state.app);
  const nav = useNavigate();
  const { data, isLoading, isFetching } = useGetProjectQuery({ divisionId: divisionId as string, projectId: projectId as string }, { skip: !divisionId || !projectId });
  const {
    data: assignedDeploymentRequests,
    isLoading: isAssignedLoading,
    isFetching: isAssignedFetching
  } = useGetAssignedDeploymentRequestsQuery({ divisionId: divisionId as string, projectId: projectId as string }, { skip: !divisionId || !projectId });
  const [updateProject] = useUpdateProjectMutation();
  const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation();
  const formik = useFormik<ProjectTypePayload>({
    enableReinitialize: true,
    validationSchema: projectPayload,
    initialValues: {
      isActive: true,
      customerProjectId: data?.customerProjectId ?? '',
      description: data?.description ?? '',
      projectType: data?.projectType ?? '',
      isBudgetOnHold: data?.isBudgetOnHold ?? false,
      owner: {
        fullName: user?.name ?? '',
        userId: user?.sub ?? ''
      },
      status: data?.status ?? '',
      tasks: data?.tasks ?? [],
      users: data?.users ?? [],
      userDefinedFields: data?.userDefinedFields ?? []
    },
    onSubmit: async (vals) => {
      try {
        if (!divisionId || !projectId) return message.error('An error occured, the team has been notified');
        const response = await updateProject({ divisionId, projectId, payload: vals }).unwrap();

        message.success(`${response.customerProjectId} was successfully updated`);

        if (exit) nav(`/${divisionId}/projects`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  const handleDelete = async (): Promise<void> => {
    if (!divisionId || !projectId) return message.error('An error occured, the team has been notified');
    if (assignedDeploymentRequests?.totalCount) return message.error(`This project can't be deleted because it is assigned to ${assignedDeploymentRequests.totalCount} deplployment request(s).`);
    try {
      await deleteProject({ divisionId, projectId });
      nav(`/${divisionId}/projects`);
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  if (isLoading || isFetching || isAssignedLoading || isAssignedFetching) return <Loader />;

  return (
    <Spin spinning={formik.isSubmitting || isDeleting} indicator={<Loader message={isDeleting ? 'Deleting Project' : 'Updating Project'} />}>
      <FormikProvider value={formik}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Row justify="space-between">
            <Col>
              <Typography.Title style={{ padding: 0, margin: 0, fontWeight: 300 }} level={4}>
                Edit Project
              </Typography.Title>
            </Col>
            <ActionControls deleteButtonProps={{ handleDelete, description: 'project' }} />
          </Row>
          <HeaderCard title="General" headerColor="rgb(206, 213, 242)">
            <ProjectForm />
          </HeaderCard>
          <TasksList />
        </Space>
      </FormikProvider>
    </Spin>
  );
};
