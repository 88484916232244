import { Button, Card, Col, Divider, Modal, Row, Space, Spin, Tooltip, Typography, message } from 'antd';
import { EditTaskModal } from 'components/molecules/EditTaskModal';
import { useField } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { Task } from 'models/Project';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useParams } from 'react-router-dom';
import { useGetAssignedDeploymentRequestsTasksQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import remove from '../../common/assets/delete-lottie.json';
import edit from '../../common/assets/edit-lottie.json';

interface Props {
  task: Task;
  index: number;
}
const styles: InlineStylesModel = {
  card: {
    backgroundColor: 'white',
    borderRadius: 5,
    marginTop: 5,
    height: 185
  },
  categoryId: {
    color: 'grey',
    lineHeight: '.7',
    paddingBottom: '13px'
  },
  manufacturer: {
    fontWeight: 600
  },
  dispositionRuleType: {
    color: 'grey',
    height: '15px'
  },
  partDescription: {
    color: 'grey'
  },
  itemName: {
    fontWeight: 600,
    fontSize: 20,
    color: '#203175'
  },
  salesOrderType: {
    color: 'grey',
    textAlign: 'right'
  },
  robotPadding: {
    paddingTop: 5,
    cursor: 'pointer'
  },
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: 4,
    borderRadius: 100
  },
  view: {
    color: '#203175',
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer'
  },
  test: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

export const TaskCard: FC<Props> = ({ task, index }) => {
  const { divisionId, projectId } = useParams();
  const [hover, setHover] = useState<string | null>();
  const [isOpen, setIsOpen] = useState(false);
  const [{ value }, , { setValue }] = useField<Task[]>('tasks');

  const { data, isLoading, isFetching } = useGetAssignedDeploymentRequestsTasksQuery(
    { divisionId: divisionId as string, projectId: projectId as string, taskId: task.id as string },
    { skip: !divisionId || !projectId || !task.id }
  );

  const handleDelete = (): void => {
    Modal.confirm({
      title: 'Are you sure you want to delete this task? Changes will not go into effect until you save',
      onOk: () => {
        if (data?.totalCount) return message.error(`This task cannot be deleted because it is assigned to ${data?.totalCount} deployment request(s)`);
        setValue(value.filter((formTask) => formTask.customerTaskId !== task.customerTaskId));
      }
    });
  };

  return (
    <>
      <EditTaskModal index={index} isOpen={isOpen} setIsOpen={setIsOpen} />
      <Card style={styles.card}>
        <Row gutter={[2, 0]} justify="space-between" align="middle">
          <Col style={styles.itemName} span={18}>
            <Tooltip title={task?.customerTaskId}>
              <Typography.Text ellipsis>{task.customerTaskId}</Typography.Text>
            </Tooltip>
          </Col>
          <Col style={styles.salesOrderType} span={6}>
            <Spin spinning={isFetching || isLoading}>
              <Space>
                <Button
                  style={{ borderRadius: '50%', background: '#FFEFCC', borderColor: '#F8AD48' }}
                  onMouseEnter={(): void => setHover(`${index}-edit`)}
                  onMouseLeave={(): void => setHover(null)}
                  onClick={(): void => setIsOpen(true)}
                  icon={
                    <Lottie
                      animationData={edit}
                      loop={false}
                      goTo={!hover ? 0 : undefined}
                      play={hover === `${index}-edit`}
                      style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(0.8)' }}
                    />
                  }
                  type="default"
                />
                <Button
                  style={{ borderRadius: '50%', background: '#FFD4D4', borderColor: 'red' }}
                  onMouseEnter={(): void => setHover(`${index}-delete`)}
                  onMouseLeave={(): void => setHover(null)}
                  onClick={handleDelete}
                  icon={
                    <Lottie
                      animationData={remove}
                      loop={false}
                      goTo={!hover ? 0 : undefined}
                      play={hover === `${index}-delete`}
                      style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(2.0)' }}
                    />
                  }
                  type="default"
                />
              </Space>
            </Spin>
          </Col>
        </Row>
        {/* <Row style={styles.dispositionRuleType}>{task.description}</Row> */}
        <Row justify="center">
          <Col span={24} style={{ marginBottom: 10 }}>
            <Divider />
          </Col>
        </Row>
        <Row justify="space-between" align="bottom">
          <Col>
            {task.description && (
              <Row style={styles.smallGrouping} gutter={16} align="bottom">
                <Col>
                  <Typography.Text>{task.description}</Typography.Text>
                </Col>
              </Row>
            )}
          </Col>
          <Col>
            <Row style={{ padding: 4 }} gutter={16} align="bottom"></Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
