import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, List, Row, Space, Typography } from 'antd';
import EllipsesText from 'components/atoms/EllipsiesText';
import { Loader } from 'components/atoms/Loader';
import { ProjectsFilters } from 'components/molecules/ProjectsFilters';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProjectsQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  card: {
    backgroundColor: 'white',
    borderRadius: 5,
    marginBottom: 8
  },
  categoryId: {
    color: 'grey',
    lineHeight: '.7',
    paddingBottom: '13px'
  },
  manufacturer: {
    fontWeight: 600
  },
  dispositionRuleType: {
    color: 'grey',
    height: '15px'
  },
  partDescription: {
    color: 'grey'
  },
  itemName: {
    fontWeight: 600,
    fontSize: 20,
    color: '#203175'
  },
  salesOrderType: {
    color: 'grey',
    textAlign: 'right'
  },
  robotPadding: {
    paddingTop: 5,
    cursor: 'pointer'
  },
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: 4,
    borderRadius: 100
  },
  view: {
    color: '#203175',
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer'
  },
  test: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

export const ProjectsListPage = (): JSX.Element => {
  const nav = useNavigate();
  const { divisionId } = useParams();
  const { projectParams } = useAppSelector((state) => state);

  const { data: projectsData, isLoading, isFetching } = useGetProjectsQuery({ divisionId: divisionId as string, params: projectParams }, { skip: !divisionId });

  if (isLoading) return <Loader />;

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Row justify="space-between">
        <Col>
          <Typography.Text style={{ fontSize: 17, margin: 0, padding: 0 }}>Search Projects</Typography.Text>
        </Col>
        <Col>
          <Button onClick={(): void => nav(`/${divisionId}/projects/recover`)} icon={<DeleteOutlined />}>
            Recycle Bin
          </Button>
        </Col>
      </Row>
      <Row style={{ background: 'white', width: '100%', padding: 16 }}>
        <ProjectsFilters />
      </Row>
      <Row>
        <List
          grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
          style={{ width: '100%' }}
          loading={{ indicator: <Loader />, spinning: isLoading || isFetching }}
          dataSource={projectsData?.data}
          renderItem={(item): JSX.Element => (
            <Col>
              <Card hoverable style={styles.card} onClick={(): void => nav(`/${divisionId}/projects/${item.id}`)}>
                <Row gutter={[2, 0]} justify="space-between" align="middle">
                  <Col style={styles.itemName} span={18}>
                    <EllipsesText tooltipCharacterCount={23} text={item.customerProjectId} />
                  </Col>
                  <Col style={styles.salesOrderType} span={6}>
                    <EllipsesText tooltipCharacterCount={23} text={item.status} color="gray" />
                  </Col>
                </Row>
                <Row justify="space-between" align="top"></Row>
                <Row style={styles.dispositionRuleType}>{item.description}</Row>
                <Row justify="center">
                  <Col span={24} style={{ marginBottom: 10 }}>
                    <Divider />
                  </Col>
                </Row>
                <Row justify="space-between" align="bottom">
                  <Col>
                    <Row style={styles.smallGrouping} gutter={16} align="middle">
                      <Col>{item.projectType}</Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        />
      </Row>
    </Space>
  );
};
