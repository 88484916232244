import { Col, Row, Space } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { FieldSwitchInput } from 'components/atoms/FieldSwitchInput';

export const ProjectForm = (): JSX.Element => {
  return (
    <Row gutter={[50, 16]}>
      <Col span={8}>
        <Space style={{ width: '100%' }} direction="vertical">
          <FieldInput fieldName="customerProjectId" label="Customer Project Id" />
          <FieldInput fieldName="description" label="Project Description" />
        </Space>
      </Col>
      <Col span={8}>
        <Space style={{ width: '100%' }} direction="vertical">
          <FieldInputSelect options={['Capital', 'Expense']} fieldName="projectType" label="Project Type" />
          <FieldInputSelect options={['Active', 'Closed', 'Cancelled']} fieldName="status" label="Project Status" />
        </Space>
      </Col>
      <Col span={8}>
        <Space style={{ width: '100%' }} direction="vertical">
          <FieldSwitchInput fieldName="isBudgetOnHold" label="Is budget on hold?" />
        </Space>
      </Col>
    </Row>
  );
};
