import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectParams } from 'models/Project';

const initialState: ProjectParams = {
  projectNameContains: undefined,
  returnDeletedDataOnly: undefined,
  orderByDirection: 'descending',
  overrideSkipTake: true,
  projectDescriptionContains: undefined,
  projectStatus: undefined
};

export const projectParams = createSlice({
  name: 'projectParams',
  initialState,
  reducers: {
    setProjectNameContains: (state, { payload }: PayloadAction<ProjectParams['projectNameContains']>) => {
      state.projectNameContains = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<ProjectParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<ProjectParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setOverrideSkipTake: (state, { payload }: PayloadAction<ProjectParams['overrideSkipTake']>) => {
      state.overrideSkipTake = payload;
    },
    setProjectDescriptionContains: (state, { payload }: PayloadAction<ProjectParams['projectDescriptionContains']>) => {
      state.projectDescriptionContains = payload;
    },
    setProjectStatus: (state, { payload }: PayloadAction<ProjectParams['projectStatus']>) => {
      state.projectStatus = payload;
    }
  }
});

export const { setProjectNameContains, setReturnDeletedDataOnly, setOrderByDirection, setOverrideSkipTake, setProjectDescriptionContains, setProjectStatus } = projectParams.actions;
