import * as yup from 'yup';

export interface ProjectType {
  business: {
    dataAreaId: string;
    erpId: string;
    id: string;
  };
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  customerProjectId: string;
  division: {
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isActive: boolean;
  isBudgetOnHold: boolean;
  isDeleted: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  owner: {
    fullName: string;
    userId: string;
  };
  partitionKey: string;
  partitionKeyDescription: string;
  projectType: string;
  description: string;
  status: string;
  tasks: Task[];
  userDefinedFields: [];
  users: [
    {
      fullName: string;
      userId: string;
    }
  ];
  _rid: string;
  _self: string;
  _etag: string;
}

export interface Task {
  description: string;
  customerTaskId: string;
  id?: string;
}

export interface ProjectTypePayload {
  isActive?: boolean;
  customerProjectId: string;
  description?: string;
  isBudgetOnHold?: boolean;
  owner: {
    fullName: string;
    userId: string;
  };
  projectType: string;
  users?: ProjectUsers[] | null;
  status: string;
  tasks?: Task[];
  userDefinedFields?: string[] | null;
}

export interface ProjectUsers {
  fullName: string;
  userId: string;
}

export interface ProjectParams {
  projectNameContains?: string;
  projectDescriptionContains?: string;
  returnDeletedDataOnly?: boolean;
  orderByDirection?: string;
  overrideSkipTake?: boolean;
  projectStatus?: string;
}

export interface AssignedDeploymentRequest {
  customerPurchaseOrderNumber: string;
  customerReferenceNumber: string;
  division: {
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  partitionKey: string;
  partitionKeyDescription: string;
  project: {
    id: string;
    name: string;
    task: {
      customerTaskId: string;
      description: string;
      id: string;
      name: string;
    };
  };
  requestNumber: string;
  salesOrderNumber: string;
  status: string;
}

export const projectPayload: yup.SchemaOf<ProjectTypePayload> = yup.object({
  description: yup.string().label('Project description'),
  customerProjectId: yup.string().label('Customer Project Id').required(),
  isActive: yup.boolean(),
  isBudgetOnHold: yup.boolean(),
  owner: yup.object().shape({
    fullName: yup.string().required(),
    userId: yup.string().required()
  }),
  projectType: yup.string().label('Project type').required(),
  status: yup.string().label('Status').required(),
  tasks: yup.array(),
  users: yup.array().nullable(),
  userDefinedFields: yup.array().nullable()
});
