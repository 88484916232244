import { Space } from 'antd';
import { FancyInput } from 'components/atoms/FancyInput';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React, { ChangeEvent, useEffect } from 'react';
import { setCustomerSearch, setInheritanceTypeFilter } from 'redux/slices/businessListSlice';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const HomeControls: React.FC = () => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */

  const handleCustomerSearchChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerSearch(event.target.value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCustomerSearch(undefined));
      dispatch(setInheritanceTypeFilter(['Business', 'Division', 'BusinessWithDivisionOverride']));
    };
  }, [dispatch]);

  /* ******************** Renderer ******************** */
  return (
    <Space align="center" style={{ marginTop: 8 }}>
      <AntPageTitle text="Customers" />
      <FancyInput placeholder="Search customer" onChange={handleCustomerSearchChange} />
    </Space>
  );
};
