import { About } from 'pages/about/About';
import { CreateProjectPage } from 'pages/createProject/CreateProjectPage';
import { EditProfilePage } from 'pages/editProfile/EditProfile';
import { EditProjectPage } from 'pages/editProject/EditProjectPage';
import { Forbidden } from 'pages/forbidden/Forbidden';
import { Home } from 'pages/home/Home';
import { ProjectsListPage } from 'pages/projects/ProjectsListPage';
import { Recover } from 'pages/recover/Recover';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'redux/store';

export const MainRoutes = (): JSX.Element => {
  const { user, acuityContext } = useAppSelector((state) => state.app);
  const isMdsiEmployee = user ? user['https://acuity.mdsiinc.com/user/user_metadata'].is_mdsi_employee : null;

  const navigator = (): string => {
    if (acuityContext) return `/${acuityContext?.selectedCustomer.id}/projects`;
    if (!acuityContext && !isMdsiEmployee) return '/unauthorized';

    return '/internal';
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate to={navigator()} />} />
      <Route path="/internal" element={isMdsiEmployee ? <Home /> : !isMdsiEmployee && acuityContext ? <Navigate to={`/${acuityContext?.selectedCustomer.id}/projects`} /> : <Forbidden />} />
      <Route path="/about" element={<About />} />
      <Route path="/unauthorized" element={<Forbidden />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/:divisionId/projects" element={<ProjectsListPage />} />
      <Route path="/:divisionId/projects/recover" element={<Recover />} />
      <Route path="/:divisionId/projects/create" element={<CreateProjectPage />} />
      <Route path="/:divisionId/projects/:projectId" element={<EditProjectPage />} />
    </Routes>
  );
};
