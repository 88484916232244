import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ApiMultipleResponse } from 'models/ApiModels';
import { AssignedDeploymentRequest, ProjectParams, ProjectType, ProjectTypePayload } from 'models/Project';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_MRS_GROUCH_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_MRS_GROUCH;

export const mrsGrouchApi = createApi({
  reducerPath: 'mrsGrouchApi',
  tagTypes: ['Projects', 'Project'],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProjects: builder.query<ApiMultipleResponse<ProjectType>, { divisionId: string; params: ProjectParams }>({
      query: ({ divisionId, params }) => ({
        url: `/divisions/${divisionId}/projects`,
        params
      }),
      providesTags: ['Projects']
    }),
    getAssignedDeploymentRequests: builder.query<ApiMultipleResponse<AssignedDeploymentRequest>, { divisionId: string; projectId: string }>({
      query: ({ divisionId, projectId }) => ({
        url: `/divisions/${divisionId}/projects/${projectId}/deploymentRequestAssignments`
      })
    }),
    getAssignedDeploymentRequestsTasks: builder.query<ApiMultipleResponse<AssignedDeploymentRequest>, { divisionId: string; projectId: string; taskId: string }>({
      query: ({ divisionId, projectId, taskId }) => ({
        url: `/divisions/${divisionId}/projects/${projectId}/tasks/${taskId}/deploymentRequestAssignments`
      })
    }),
    getProject: builder.query<ProjectType, { divisionId: string; projectId: string }>({
      query: ({ divisionId, projectId }) => ({
        url: `/divisions/${divisionId}/projects/${projectId}`
      }),
      providesTags: ['Project']
    }),
    updateProject: builder.mutation<ProjectType, { divisionId: string; projectId: string; payload: ProjectTypePayload }>({
      query: ({ divisionId, projectId, payload }) => ({
        url: `/divisions/${divisionId}/projects/${projectId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['Project', 'Projects']
    }),
    createProject: builder.mutation<ProjectType, { divisionId: string; payload: ProjectTypePayload }>({
      query: ({ divisionId, payload }) => ({
        url: `/divisions/${divisionId}/projects/`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Project', 'Projects']
    }),
    deleteProject: builder.mutation<ProjectType, { divisionId: string; projectId: string }>({
      query: ({ divisionId, projectId }) => ({
        url: `/divisions/${divisionId}/projects/${projectId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Project', 'Projects']
    }),
    recoverProject: builder.mutation<ProjectType, { divisionId: string; projectId: string }>({
      query: ({ divisionId, projectId }) => ({
        url: `/divisions/${divisionId}/projects/${projectId}/recover`,
        responseHandler: (response): Promise<ApiMultipleResponse<ProjectType> | string> => (response.status >= 300 && typeof response.status === 'string' ? response.text() : response.json())
      }),
      invalidatesTags: ['Project', 'Projects']
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useGetVersionQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetApiNameQuery,
  useRecoverProjectMutation,
  useGetAssignedDeploymentRequestsQuery,
  useGetAssignedDeploymentRequestsTasksQuery
} = mrsGrouchApi;
