import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectType } from 'models/Project';

interface InitialState {
  selectedProjects: ProjectType[];
  isProjectRcovering: boolean;
}

const initialState: InitialState = {
  selectedProjects: [],
  isProjectRcovering: false
};

export const selectedProjectsSlice = createSlice({
  name: 'selectedProjects',
  initialState,
  reducers: {
    setSelectedProjects: (state, { payload }: PayloadAction<ProjectType[]>) => {
      state.selectedProjects = payload;
    },
    setIsProjectRcovering: (state, { payload }: PayloadAction<boolean>) => {
      state.isProjectRcovering = payload;
    },
    addSelectedProject: (state, { payload }: PayloadAction<ProjectType>) => {
      state.selectedProjects = [...state.selectedProjects, payload];
    },
    removeSelectedProjcet: (state, { payload }: PayloadAction<ProjectType>) => {
      state.selectedProjects = state.selectedProjects.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedProjects, removeSelectedProjcet, addSelectedProject, setIsProjectRcovering } = selectedProjectsSlice.actions;
