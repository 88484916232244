import { DeleteOutlined, EllipsisOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Col, Dropdown, MenuProps, Modal, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setExit } from 'redux/slices/appSlice';

interface Props {
  deleteButtonProps?: {
    handleDelete: () => Promise<void>;
    description: string;
  };
}

export const ActionControls: FC<Props> = ({ deleteButtonProps }) => {
  const { submitForm } = useFormikContext();
  const { divisionId } = useParams();
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const items: MenuProps['items'] = [
    {
      key: 'save',
      label: <Typography.Text>Save</Typography.Text>,
      onClick: async (): Promise<void> => await submitForm()
    }
  ];

  const handleSubmitAndExit = async (): Promise<void> => {
    dispatch(setExit(true));
    await submitForm();
    dispatch(setExit(false));
  };

  return (
    <Col>
      <Space>
        <Button onClick={(): void => navigate(`/${divisionId}/projects`)}>Back</Button>
        {contextHolder}
        <Button
          danger
          onClick={(): void => {
            modal.confirm({
              onOk: deleteButtonProps?.handleDelete,
              title: `Are you sure you want to delete this ${deleteButtonProps?.description}?`,
              okText: 'Delete',
              icon: <WarningFilled style={{ color: 'red' }} />,
              okButtonProps: { style: { background: 'red', border: 'none' } },
              prefixCls: 'ant-modal'
            });
          }}
          icon={<DeleteOutlined />}
          style={{ color: 'red', marginRight: 8 }}>
          Delete
        </Button>
        <Dropdown.Button onClick={handleSubmitAndExit} menu={{ items }} type="primary" icon={<EllipsisOutlined />}>
          Save and Exit
        </Dropdown.Button>
      </Space>
    </Col>
  );
};
