import { Col, Modal, Space, Typography, message } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { ProjectTypePayload, Task } from 'models/Project';
import { FC, Fragment, useEffect } from 'react';
import { taskPayload } from './EditTaskModal';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddTaskModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const { setValues, values: formVals } = useFormikContext<ProjectTypePayload>();

  const addFormik = useFormik<Task>({
    enableReinitialize: true,
    validationSchema: taskPayload,
    initialValues: {
      customerTaskId: '',
      description: ''
    },
    onSubmit: (values): void => {
      if (formVals?.tasks?.some((task) => task.customerTaskId === values.customerTaskId)) {
        message.error('Customer task id already exists');

        return;
      }
      setValues((prev) => ({
        ...prev,
        tasks: [...(prev.tasks ? prev.tasks : []), values]
      }));
      addFormik.resetForm();
      setIsOpen(false);
    }
  });

  const handleCancel = (): void => {
    setIsOpen(false);
    addFormik.resetForm();
  };

  useEffect(() => {
    if (!isOpen && addFormik.errors) {
      addFormik.setTouched({ customerTaskId: false, description: false });
    }
  }, [isOpen, addFormik.errors]);

  return (
    <Fragment>
      <Modal destroyOnClose okText="Submit" onOk={(): Promise<void> => addFormik.submitForm()} onCancel={handleCancel} open={isOpen}>
        <FormikProvider value={addFormik}>
          <Col span={24} style={{ background: 'rgb(255, 234, 193)', borderRadius: 10, marginBottom: 10, marginTop: 20 }}>
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
              Add New Task
            </Typography.Title>
          </Col>
          <Col style={{ margin: '20px 10px' }}>
            <Space style={{ width: '100%' }} direction="vertical" size={15}>
              <FieldInput label="Customer Task Id" fieldName="customerTaskId" />
              <FieldInput label="Customer Task Description" fieldName="description" />
            </Space>
          </Col>
        </FormikProvider>
      </Modal>
    </Fragment>
  );
};
